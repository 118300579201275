import React, { useEffect, useRef, useState } from "react";
import { CheckIcon } from "lucide-react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

interface Option {
  id: string;
  name: string;
  [key: string]: any; // Allow for additional properties
}

interface GenericDropdownProps<T extends Option> {
  options: T[];
  onSelect: (options: T[]) => void;
  initialSelectedId: string[];
  renderOption?: (option: T, isSelected: boolean) => React.ReactNode;
  buttonClassName?: string;
  dropdownClassName?: string;
}

function GenericMultiDropdown<T extends Option>({
  options,
  onSelect,
  initialSelectedId,
  renderOption,
  buttonClassName = "",
  dropdownClassName = "",
}: GenericDropdownProps<T>) {
  const [selected, setSelected] = useState<T[]>(
    () => options.filter((opt) => initialSelectedId?.includes(opt.id) ) || options
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSelection = (option: T[]) => {
    if(option.length === 0) {
      toast("Minimum one paramter is required",{type: 'warning',autoClose: 2000});
      return;
    }
    setSelected(option);
    setIsOpen(false);
    onSelect(option);
  };

  const getButtonText = () => {
    if (selected.length === 0) return "Select parameters";
    if (selected.length === 1) return selected[0].name;
    if (selected.length === 2) return `${selected[0].name}, ${selected[1].name}`;
    return `${selected[0].name}, ${selected[1].name} +${selected.length - 2}`;
  };

  return (
    <div className={`relative ${buttonClassName}`} ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`h-11 cursor-pointer rounded-xl bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6 ${buttonClassName}`}
      >
        <span className="block truncate font-semibold">
          {getButtonText()}
        </span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </span>
      </button>

      {isOpen && (
        <ul
          className={`absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${dropdownClassName}`}
        >
          {options.map((option) => (
            <li
              key={option.id}
              onClick={() => {
                if (selected.some(select => select.id === option.id)) {
                  handleSelection(selected.filter(item => item !== option));
                } else {
                  handleSelection([...selected, option]);
                }
              }}
              className={`group  relative min-w-28 items-center cursor-pointer hover:bg-indigo-600 hover:text-white select-none py-2 pl-3 pr-9 text-gray-900`}
            >
              {renderOption ? (
                renderOption(option, selected.some( select => select.id === option.id))
              ) : (
                <div>
                  <span
                    className={`block truncate font-normal ${
                      selected.some( select => select.id === option.id)? "font-semibold" : ""
                    }`}
                  >
                    {option.name}
                  </span>
                  {selected.some( select => select.id === option.id) && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                      <CheckIcon className="h-5 w-5" />
                    </span>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default GenericMultiDropdown;
