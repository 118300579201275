import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLogin } from "../../queries/auth";
import { SignInDetailsModel } from "../../types/auth";
import { useAuthStore } from "../../store/useAuthStore";
import { decodeAuthToken } from "../../utils/auth";
import { toast } from "react-toastify";

export default function LoginPage() {
  const { id, key } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [email, setEmail] = useState(id ?? "");
  const [password, setPassword] = useState(key ?? "");

  useEffect(() => {
    const id = searchParams.get("id");
    const key = searchParams.get("key");
    if (id) {
      // Decode the email address properly
      const decodedEmail = decodeURIComponent(id).replace(" ", "+");
      setEmail(decodedEmail);
    }

    if (key) setPassword(key);

    // Remove id and key from URL
    if (id || key) {
      searchParams.delete("id");
      searchParams.delete("key");
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams]);

  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { mutate: login } = useLogin();
  const { setAuth } = useAuthStore();
  const length = 3;
  const [activeIndex, setActiveIndex] = useState(0);
  let count = 0;
  useEffect(() => {
    //timer to change the login image on right side
    const intervalId = setInterval(() => {
      count++;
      setActiveIndex(count % length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  function handleLogin() {
    const loginDto: SignInDetailsModel = { email, password };
    login(loginDto, {
      onSuccess: (data) => {
        const accessToken = data.data.token;
        const decodedJwt = decodeAuthToken(accessToken);
        setAuth({
          accessToken,
          isAuthenticated: true,
          email: decodedJwt.email,
          id: decodedJwt.id,
          role: decodedJwt.role,
        });
        setEmail("");
        setPassword("");
        toast("Logged in successfully!", {
          type: "success",
          autoClose: 2000,
        });
        if (decodedJwt.role === "ADMIN") {
          navigate("/user");
        } else {
          navigate("/dashboard");
        }
      },
      onError: (data: any) => {
        toast(data.response.data.errorMessage, {
          type: "error",
          autoClose: 2000,
        });
      },
    });
  }

  return (
    <>
      <div className="flex w-screen flex-wrap text-slate-800">
        <div className="flex w-full flex-col md:w-1/2">
          <div className="flex justify-center pt-12 md:justify-start md:pl-12">
            <a href="#" className="text-2xl font-bold text-blue-600">
              {" "}
              Sensormagics
            </a>
          </div>
          <div className="my-auto mx-auto flex flex-col justify-center px-6 pt-8 md:justify-start lg:w-[28rem]">
            <p className="text-center text-3xl font-bold md:leading-tight md:text-left md:text-5xl">
              Welcome back <br />
              to <span className="text-blue-600">Sensormagics</span>
            </p>
            <p className="mt-6 text-center font-medium md:text-left">
              Sign in to your account below.
            </p>

            <div className="flex flex-col items-stretch pt-3 md:pt-8">
              <div className="flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="login-email"
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="mb-4 flex flex-col pt-4">
                <div className="relative flex overflow-hidden rounded-md border-2 transition focus-within:border-blue-600">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type={showPassword ? "text" : "password"}
                    id="login-password"
                    className="w-full flex-shrink appearance-none border-gray-300 bg-white py-2 px-4 text-base text-gray-700 placeholder-gray-400 focus:outline-none"
                    placeholder="Password"
                  />
                </div>
              </div>
              <a
                onClick={() => {
                  navigate("/forgotPassword");
                }}
                className="mb-6 text-center text-sm font-medium text-gray-600 md:text-left"
              >
                Forgot password?
              </a>
              <button
                onClick={handleLogin}
                className="rounded-lg bg-blue-600 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-700 focus:ring-2 md:w-32"
              >
                Sign in
              </button>
            </div>
            <div className="py-12 text-center">
              <p className="text-gray-600">
                Don't have an account?
                <a
                  href="/support"
                  className="whitespace-nowrap font-semibold text-gray-900 underline underline-offset-4 ml-2"
                >
                  Contact us
                </a>
              </p>
            </div>
          </div>
        </div>
        <div className="relative hidden h-screen select-none bg-blue-600 bg-gradient-to-br md:block md:w-1/2">
          <div className="py-16 px-8 text-white xl:w-[40rem]">
            <span className="rounded-full bg-white px-3 py-1 font-medium text-blue-600">
              Sensormagics
            </span>
            <p className="my-6 text-3xl font-semibold leading-10">
              Monitor the Air Quality for better tomorrow
            </p>
            <p className="mb-4">
              {" "}
              Always know the status of your warehouse, extensive analysis of
              your air quality
            </p>
            <a
              href="https://sensormagics.com"
              target="_blank"
              className="font-semibold tracking-wide text-white underline underline-offset-4"
            >
              Learn More
            </a>
          </div>
          <img
            className="ml-8 w-11/12 max-w-lg rounded-lg object-cover"
            src="/image.png"
          />
        </div>
      </div>
    </>
  );
}

// <div className="flex h-screen bg-white">
// <div className="flex w-1/2 bg-white flex-col items-center justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 max-h-full">
//   <div className="mx-auto justify-center items-center w-full max-w-sm lg:w-96 mb-24 lg:border-none lg:shadow-none lg:px-0 lg:pt-0 lg:pb-0 rounded-md px-6 pt-10 pb-16">
//     <div>
//       <HStack className="items-baseline">
//         <img
//           className="h-10 w-auto"
//           src="/onlylogo.png"
//           alt="Your Company"
//         />

//       </HStack>

//       <h2 className="mt-8 mx-auto text-3xl font-semibold leading-9 tracking-tight text-gray-900">
//         Login to your account
//       </h2>
//     </div>

//     <div className="mt-10">
//       <div className="space-y-4">
//         <div>
//           <label
//             htmlFor="email"
//             className="block text-sm font-medium leading-6 text-gray-900"
//           >
//             Email
//           </label>
//           <div className="relative mt-2 items-center">

//             <input
//               id="email"
//               name="email"
//               type="email"
//               value={email}
//               placeholder="Enter your mail address"
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               className="block py-3 w-full px-2 rounded-md  pl-6 border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//             />
//           </div>
//         </div>

//         <div>
//           <label
//             htmlFor="password"
//             className="block text-sm font-medium leading-6 text-gray-900"
//           >
//             Password
//           </label>
//           <div className="relative mt-2">

//             <input
//               id="password"
//               name="password"
//               placeholder="Enter Password"
//               value={password}
//               type={showPassword ? "text" : "password"}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//               className="block w-full px-2 rounded-md border-0 pl-6 py-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
//             />
//           </div>
//         </div>
//         <div className="flex items-center justify-between w-full">
//           <div className="flex items-center">
//             <input
//               id="remember-me"
//               name="remember-me"
//               type="checkbox"
//               checked={showPassword}
//               onChange={() => setShowPassword(!showPassword)}
//               className="h-4 w-4 rounded cursor-pointer pl-2 border-gray-300 text-indigo-600 border-2 focus:ring-indigo-600"
//             />
//             <label
//               htmlFor="remember-me"
//               className="ml-3 block text-sm cursor-pointer leading-6 text-gray-900"
//             >
//               Show Password
//             </label>
//           </div>
//           <div
//             onClick={() => {
//               navigate("/forgotPassword");
//             }}
//             className="text-sm cursor-pointer font-bold underline text-primary"
//           >
//             Forgot your password?
//           </div>
//         </div>

//         <button
//           type="submit"
//           onClick={handleLogin}
//           className="flex w-full cursor-pointer justify-center rounded-md bg-primary px-4 py-3 text-md font-semibold leading-6 text-white shadow-sm hover:bg-primary/60 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
//         >
//           Log in
//         </button>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="relative hidden w-1/2 lg:block bg-[#F5FAF4]  rounded-lg">

//   <VStack className="gap-8">
//     <img
//       className="inset-0 md:visible invisible object-cover mx-auto w-fit px-32"
//       src={activeIndex === 0 ? "remo.png" : activeIndex === 1 ? "remo.png" : "remo.png"}
//       alt=""
//     />

//     <div className="px-10 text-2xl font-semibold text-center text-gray-600">
//       {activeIndex === 0 ? <div> <p>Live Workspace Monitor</p>
//         <p className="text-base text-gray-500">
//           Monitor the Air Quality for better tomorrow
//         </p>
//       </div> : activeIndex === 1 ? <div> <p>Monitor Warehouse from anywhere</p>
//         <p className="text-base text-gray-500">
//           Always know the status of your warehouse, extensive analysis of your air quality
//         </p>
//       </div> : <div> <p>Analyze your air quality</p>
//         <p className="text-base text-gray-500">
//           extensive analysis of your air quality
//         </p>
//       </div>}
//       <HStack className="gap-4 mt-6 w-full justify-center">
//         <div
//           onClick={() => setActiveIndex(0)}
//           className={`w-4 h-4 cursor-pointer ${activeIndex === 0 ? "bg-gray-500" : " bg-transparent border-2 border-gray-500"} rounded-full`}
//         ></div>
//         <div
//           onClick={() => setActiveIndex(1)}
//           className={`w-4 h-4 cursor-pointer ${activeIndex === 1 ? "bg-gray-500" : " bg-transparent border-2 border-gray-500"} rounded-full`}
//         ></div>
//         <div
//           onClick={() => setActiveIndex(2)}
//           className={`w-4 h-4 cursor-pointer ${activeIndex === 2 ? "bg-gray-500" : " bg-transparent border-2 border-gray-500"} rounded-full`}
//         ></div>
//       </HStack>
//     </div>
//   </VStack>
// </div>
// </div>
