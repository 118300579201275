import React from "react";

const InfoRow = ({
  title,
  dotColor,
  value,
  subtitle,
}: {
  title: string;
  dotColor: string;
  value: string | number;
  subtitle?: string;
}) => {
  return (
    <div className="flex flex-col h-full">
      {/* Header with dot and title */}
      <div className="flex items-center gap-2 mb-2">
        <div
          className={`w-2 h-2 rounded-full`}
          style={{ backgroundColor: dotColor }}
        />
        <h3 className="font-medium text-gray-600 text-sm">{title}</h3>
      </div>
      
      {/* Value display */}
      <div className="text-xl font-semibold text-gray-900">
        {value}
      </div>

      {/* Optional subtitle */}
      {subtitle && (
        <div className="text-xs text-gray-500 mt-1">
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default InfoRow;
