import DataCards from "./DataCards";
import { HStack, VStack } from "../../component/utils";
import { CardModel, extractDashboardCardValues } from "./GenerateDashboardData";
import { useGetDeviceRange, useGetUserDevices } from "../../queries/admin";
import { IWeatherData } from "../../types/device";
import { useEffect, useState } from "react";
import { useValueStore } from "../../store/useValueState";
import BentoGrid from "./BentoGrid";
import AQISensor from "./AQISensor";
import PMDataCards from "./PMCards";
import Bento2 from "./Bento2";

const HomePageDashboardCard = ({ liveData }: { liveData?: IWeatherData }) => {
  const { data: user } = useGetUserDevices();
  const { deviceId } = useValueStore();
  const { data: deviceRange } = useGetDeviceRange(deviceId ?? "");
  const { temp_Humid_Pres_Co2, pm1_10_25_4, Odor_Mold_Occup_Vent, gas1, gas2, gas3_gas4_gas5_gas6, pm_voc_light_noise, productivityMeter } =
    extractDashboardCardValues(liveData, user?.gasMapping, deviceRange);
  const [particulateValue, setParticulateValue] = useState(0);
  useEffect(() => {
    if (!!liveData) {
      const pmValues = [
        liveData.pm1,
        liveData.pm10,
        liveData.pm25,
        liveData.pm4,
      ];
      const totalPmValues = pmValues.reduce((sum, value) => sum + value, 0);
      setParticulateValue(totalPmValues / pmValues.length);
    }
  }, [liveData]);
  console.log("liveData", liveData);
  console.log("productivityMeter", productivityMeter);
  return (
    <VStack className="gap-6">
      <div className="flex flex-wrap gap-6 w-full">
        {temp_Humid_Pres_Co2.map((ele: CardModel) => (
          <DataCards key={ele.name} value={ele} />
        ))}
      </div>
      <div className="flex flex-wrap gap-6 w-full">
        {pm_voc_light_noise.map((ele: CardModel) => (
          <DataCards key={ele.name} value={ele} />
        ))}
      </div>
      {/* <Bento2 /> */}

      <BentoGrid Odor_Mold_Occup_Vent={Odor_Mold_Occup_Vent} productivityMeter={[productivityMeter]} liveData={liveData} aqi={gas1[0]} deviceRange={deviceRange} />


      <div className="grid grid-cols-4 gap-6 w-full">
        {gas1.map((ele: CardModel) => (
          <DataCards isEnabled={false} key={ele.name} value={ele} />
        ))}
        <div className="flex flex-col col-span-2 w-full">
          <div className="flex flex-row w-full">
            {pm1_10_25_4.slice(0, 2).map((ele: CardModel, index: number) => (
              <PMDataCards key={ele.name} value={ele} rounded={index === 0 ? "topLeft" : "topRight"} />
            ))}
          </div>
          <div className="flex flex-row w-full">
            {pm1_10_25_4.slice(2, 4).map((ele: CardModel, index: number) => (
              <PMDataCards key={ele.name} value={ele} rounded={index === 0 ? "bottomLeft" : "bottomRight"} />
            ))}
          </div>
        </div>
        {gas2.map((ele: CardModel) => (
          <DataCards isEnabled={false} key={ele.name} value={ele} />
        ))}
      </div>
      <div className="flex flex-wrap gap-6 w-full">
        {gas3_gas4_gas5_gas6.map((ele: CardModel) => (
          <DataCards isEnabled={false} key={ele.name} value={ele} />
        ))}
      </div>
    </VStack>
  );
};

export default HomePageDashboardCard;
