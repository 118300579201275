const FooterSection = ({ date }: { date: string }) => {
  // const links = [
  //   {
  //     title: "Sensormagics",
  //     icon: (
  //       <img
  //         src="onlylogo.png"
  //         width={20}
  //         height={20}
  //         alt="sensormagics logo"
  //       />
  //     ),
  //     href: "#",
  //   },
  //   {
  //     title: "Mail",
  //     icon: <Mail className="h-full w-full text-neutral-500 " />,
  //     href: "#",
  //   },

  //   {
  //     title: "Facebook",
  //     icon: <Facebook className="h-full w-full text-neutral-500 " />,
  //     href: "#",
  //   },

  //   {
  //     title: "Linkedin",
  //     icon: <Linkedin className="h-full w-full text-neutral-500 " />,
  //     href: "#",
  //   },

  //   {
  //     title: "Twitter",
  //     icon: <Twitter className="h-full w-full text-neutral-500 " />,
  //     href: "#",
  //   },
  // ];

  return (


    <footer className="bg-white rounded-xl drop-shadow-sm max-w-7xl">
      <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2025
          <a target="_blank" href="https://sensormagics.com/" className="hover:underline ml-1">SensorMagics™</a>. All Rights Reserved.
        </span>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          {/* <li>
            <a target="_blank" href="https://sensormagics.com/" className="hover:underline me-4 md:me-6">About</a>
          </li>
          <li>
            <a href="#" className="hover:underline me-4 md:me-6">Privacy Policy</a>
          </li>
          <li>
            <a href="#" className="hover:underline me-4 md:me-6">Licensing</a>
          </li>
          <li>
            <a target="_blank" href="/support" className="hover:underline">Contact</a>
          </li> */}
        </ul>
      </div>
    </footer>

    // <div>
    //   <HStack className="bg-white rounded-xl drop-shadow-box p-4 w-full justify-between">
    //     <HStack className="items-baseline relative justify-between w-full">
    //       <div className="absolute mt-4 ml-4">
    //         <p className="text-center text-xs leading-5 text-gray-500">
    //           &copy; 2024 Sensormagics. All rights reserved.
    //         </p>
    //       </div>
    //       <div></div>

    //       <HStack className="gap-8">
    //         <div className="flex items-center justify-center w-full">
    //           <FloatingDock
    //             mobileClassName="translate-y-6" // only for demo, remove for production
    //             items={links}
    //           />
    //         </div>
    //       </HStack>
    //     </HStack>
    //   </HStack>
    // </div>
  );
};

export default FooterSection;
