import React, { useState, useEffect } from "react";
import { X, Plus } from "lucide-react";
import { useGenerateReport } from "../../queries/admin";
import { useAuthStore } from "../../store/useAuthStore";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { metricDataMapping } from "../analytics/AnalyticsPage";
import DatePicker from "./DatePicker";
import { HStack } from "../../component/utils";
import { Icon } from "@iconify/react";

const parameters = [
  "Temperature",
  "Humidity",
  "Pressure",
  "Differential Pressure",
  "Carbon Dioxide",
  "VOCs",
  "Light",
  "Noise",
  "PM1",
  "PM2.5",
  "PM4",
  "PM10",
  "AIQ",
  "Gas 1",
  "Gas 2",
  "Gas 3",
  "Gas 4",
  "Gas 5",
  "Gas 6",
  "Productivity Meter",
];

const ParameterSelectorReport: React.FC = () => {
  const [selected, setSelected] = useState<string[]>([]);
  const { id } = useAuthStore();
  const { mutate: generateReport } = useGenerateReport();
  const filteredParameters = parameters.filter(
    (param) => !selected.includes(param)
  );

  useEffect(() => {
    setSelected(parameters);
  }, []);

  const handleSelect = (param: string) => {
    setSelected([...selected, param]);
  };

  const handleRemove = (param: string) => {
    setSelected(selected.filter((p) => p !== param));
  };

  const handleSelectAll = () => {
    setSelected(parameters);
  };

  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [selectedToDate, setSelectedToDate] = useState<string>("");

  return (
    <div className="max-w-8xl mx-auto">
      <div className="p-8 mt-6">
        <div className="grid grid-cols-2 gap-6 mb-8">
          <DatePicker
            label="From Date"
            value={selectedFromDate}
            onChange={setSelectedFromDate}
          />
          <DatePicker
            label="To Date"
            value={selectedToDate}
            onChange={setSelectedToDate}
          />
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Select Parameters for Report
            </h3>
            <div className="bg-gray-50 rounded-xl p-6 min-h-[120px] border border-gray-100">
              {filteredParameters.length > 0 && (
                <button
                  className="text-sm text-indigo-600 hover:text-indigo-700 font-medium mb-4 float-right"
                  onClick={handleSelectAll}
                >
                  Select all parameters
                </button>
              )}
              <div className="flex flex-wrap gap-2">
                {selected.map((param) => (
                  <span
                    key={param}
                    className="inline-flex items-center bg-indigo-100 text-indigo-800 rounded-full px-4 py-1.5 text-sm font-medium transition-all hover:bg-indigo-200"
                  >
                    {param}
                    <button
                      onClick={() => handleRemove(param)}
                      className="ml-2 text-indigo-600 hover:text-indigo-800 focus:outline-none"
                    >
                      <X size={16} />
                    </button>
                  </span>
                ))}
              </div>
              {selected.length === 0 && (
                <p className="text-gray-500 text-sm italic">
                  Selected parameters will appear here
                </p>
              )}
            </div>
          </div>

          {filteredParameters.length > 0 && (
            <div className="bg-white rounded-xl p-6 border border-gray-200">
              <div className="flex flex-wrap gap-2">
                {filteredParameters.map((param) => (
                  <button
                    key={param}
                    onClick={() => handleSelect(param)}
                    className="flex items-center bg-white border border-gray-200 rounded-full px-4 py-1.5 text-sm font-medium text-gray-700 transition-all hover:border-indigo-300 hover:bg-indigo-50"
                  >
                    <span>{param}</span>
                    <Plus size={16} className="ml-1.5 text-indigo-600" />
                  </button>
                ))}
              </div>
            </div>
          )}
          <HStack className="justify-end">
            <button
              className={`w-fit flex items-center gap-2 py-3 px-6 rounded-xl font-medium transition-all ${
                selected.length >= 2
                  ? "bg-blue-600 hover:bg-blue-500 text-white shadow-md hover:shadow-lg"
                  : "bg-gray-200 text-gray-500 cursor-not-allowed"
              }`}
              onClick={() => {
                generateReport(
                  {
                    deviceId: id || "",
                    from: format(selectedFromDate, "yyyy-MM-dd"),
                    metrics: selected.map(
                      (selectedItem) => metricDataMapping[selectedItem]
                    ),
                    to: format(selectedToDate, "yyyy-MM-dd"),
                  },
                  {
                    onSuccess() {
                      toast(
                        "Generated Report would be sent to registered email shortly",
                        {
                          type: "success",
                          autoClose: 2000,
                        }
                      );
                    },
                    onError() {
                      toast("Something went Wrong", {
                        type: "error",
                        autoClose: 2000,
                      });
                    },
                  }
                );
              }}
            >
              <Icon icon="lucide:download" className="w-4 h-4" />
              <span>Generate Report</span>
            </button>
          </HStack>
        </div>
      </div>
    </div>
  );
};

export default ParameterSelectorReport;
