import { useEffect, useState } from "react";
import ChangePasswordModal from "../../modal/ChangePasswordModal";
import LogoutFromAllDevices from "../../modal/LogoutFromAllDevicesModal";
import { useAuthStore } from "../../store/useAuthStore";
import ParameterRangeUI from "../new/SetParameterRanges";
import ParameterSelector from "../new/SelectDasshboardParam";
import DeviceDetails from "./DeviceDetail";
import { useValueStore } from "../../store/useValueState";
import { useGetUserDevices } from "../../queries/admin";
import { IDeviceModel } from "../user/CompanyPage";
import { Icon } from "@iconify/react";
import { HStack, VStack } from "../../component/utils";
import { Activity, Eye, Settings, Variable } from "lucide-react";
import { motion } from "framer-motion";

export default function DeviceSetting() {

  const [dialog, setDialog] = useState(false);

  const { role } = useAuthStore();
  const [logOutDialog, setLogoutDialog] = useState(false);

  let isAdmin = role?.toUpperCase() === "ADMIN";
  const { deviceId } = useValueStore();
  const { data: user } = useGetUserDevices();
  const [deviceInfo, setDeviceInfo] = useState<IDeviceModel>();
  useEffect(() => {
    if (user) {
      const device = user.devices.find(device => device.id === deviceId);
      if (device) {
        setDeviceInfo(device);
      }
    }
  }, [user]);
  return (
    <>
      {dialog && (
        <ChangePasswordModal
          isOpen={dialog}
          onClose={() => {
            setDialog(false);
          }}
        />
      )}
      {logOutDialog && (
        <LogoutFromAllDevices
          isOpen={logOutDialog}
          onClose={() => {
            setLogoutDialog(false);
          }}
        />
      )}
      <DeviceDetails deviceInfo={deviceInfo} />
      {/* <div className="space-y-12 bg-white rounded-xl shadow-box p-8"> */}
      {!isAdmin && (
        <div className="space-y-12 bg-white rounded-xl border border-borderColor shadow-box p-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 ">
            <header className="bg-gray-50 border-b border-gray-200 rounded-t-xl px-8 pt-4 pb-4 mb-4 -m-8 -mr-16">
              <HStack className="items-center gap-4">
                <motion.div
                  animate={{
                    scale: [1, 1.05, 1],
                    rotateZ: [0, 0.5, -0.5, 0]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                  className="w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-300 rounded-lg flex items-center justify-center shadow-lg"
                >
                  <Settings size={24} className="text-white" />
                </motion.div>

                {/* <Icon icon="lucide:carbon:parameter" className="w-16 h-16 text-gray-600" /> */}
                <div>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Set Parameter Ranges
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    Details of the optimal values of the Device
                  </p>
                </div>
              </HStack>
            </header>
            <div className="grid col-span-2 gap-x-8 gap-y-10 pb-12">
              <ParameterRangeUI />
            </div>
          </div>
        </div>
      )}
      {!isAdmin && (
        <div className="space-y-12 bg-white rounded-xl border border-borderColor shadow-box p-8 mt-8">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 ">
            <div className="bg-gray-50 border-b border-gray-200 px-8 pb-4 mb-4 -m-8 -my-4 -mr-16  rounded-t-xl">
              <HStack className="items-center gap-4">
                <motion.div
                  animate={{
                    scale: [1, 1.05, 1],
                    rotateZ: [0, 0.5, -0.5, 0]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse"
                  }}
                  className="w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-400 rounded-lg flex items-center justify-center shadow-lg"
                >
                  <Eye size={24} className="text-white" />
                </motion.div> <VStack>
                  <h2 className="text-base font-semibold leading-7 text-gray-900">
                    Set Overview Values
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600">
                    select which values matter most for you
                  </p>
                </VStack>
              </HStack>
            </div>
            <div className="grid col-span-2 gap-x-8 gap-y-10 pb-12">
              <ParameterSelector />
            </div>
          </div>
        </div>
      )}
      {/* </div> */}
    </>
  );
}
