import { VStack } from "../../component/utils";
import HomePageDashboardCard from "./HomePageDashboardCard";
import { useValueStore } from "../../store/useValueState";
import useMqttStore from "../../store/useMqttStore";
import { useEffect, useState } from "react";
import { IWeatherData } from "../../types/device";
import {
  useGetLiveData,
  useGetUserDevices,
  useGetUserPreference,
} from "../../queries/admin";
import {
  CardModelOverview,
  extractDashboardOverViewValues,
} from "./GenerateDashboardDataOverview";
import { brokerUrl } from "../../constant";
import StatsDisplay from "./HomeOverviewCard";

const HomePage = () => {
  const { deviceId } = useValueStore();
  const { deviceDataMap, setLatestDeviceData } = useMqttStore();
  const { data: userPreference } = useGetUserPreference();

  const [liveData, setLiveData] = useState<IWeatherData>();
  const [enabled, setEnabled] = useState(false);
  const { data: liveDataFromDb } = useGetLiveData(enabled, deviceId);
  //NGM do this in better way- it works now
  console.log("liveDataFromDb", liveDataFromDb);
  useEffect(() => {
    if (deviceId && deviceDataMap[deviceId]) {
      setLiveData(deviceDataMap[deviceId]);
    } else {
      setEnabled(true);
    }
  }, [deviceId, deviceDataMap]);

  useEffect(() => {
    if (enabled && deviceId && liveDataFromDb) {
      setTimeout(() => {
        setLatestDeviceData(deviceId, liveDataFromDb);
        setEnabled(false);
      }, 10);
    }
  }, [enabled, deviceId, liveDataFromDb, setLatestDeviceData]);
  const { data: user } = useGetUserDevices();
  const [listData, setListData] = useState<CardModelOverview[]>([]);
  useEffect(() => {
    if ((userPreference?.preference ?? []).length > 0) {
      const value = extractDashboardOverViewValues(
        userPreference?.preference ?? [],
        liveData,
        user?.gasMapping
      );

      setListData(value ?? []);
    }
  }, [userPreference, liveData]);

  const { connectMqtt, disconnectMqtt } = useMqttStore();

  useEffect(() => {
    connectMqtt(brokerUrl);
    return () => {
      disconnectMqtt();
    };
  }, []);
  return (
    <VStack className="gap-6">
      <StatsDisplay listData={listData} />

      <HomePageDashboardCard liveData={liveData} />
     
    </VStack>
  );
};

export default HomePage;
