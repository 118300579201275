import { CardModel } from './GenerateDashboardData';
import DataCards from './DataCards';
import AQISensor from './AQISensor';
import ProductivityMeter from './ProductivityMeter';

const BentoGrid = ({ Odor_Mold_Occup_Vent, productivityMeter, liveData, aqi, deviceRange }: { Odor_Mold_Occup_Vent: CardModel[], productivityMeter: CardModel[], liveData: any, aqi: any, deviceRange: any }) => {
    return (
        <div className="grid lg:grid-cols-4 lg:grid-rows-2 h-[350px] gap-6">
                <div className="relative col-span-1 row-span-2 h-full">
                <div className="relative flex h-full flex-col ">
               <ProductivityMeter value={productivityMeter[0]} liveData={liveData} deviceRange={deviceRange} />
                </div>
                {/* <div className="pointer-events-none absolute inset-px rounded-lg shadow ring-1 ring-black/5 lg:rounded-l-[2rem]"></div> */}
            </div>

            <div className="flex flex-col col-span-2 gap-6">
                <div className="grid grid-cols-2 gap-6 ">
                    <DataCards value={Odor_Mold_Occup_Vent[0]} />
                    <DataCards value={Odor_Mold_Occup_Vent[1]} />
                </div>
                <div className="grid grid-cols-2 gap-6 ">
                    <DataCards value={Odor_Mold_Occup_Vent[2]} />
                    <DataCards value={Odor_Mold_Occup_Vent[3]} />
                </div>
            </div>

            <div className="relative col-span-1 row-span-2 h-full">
                <div className="relative flex h-full flex-col overflow-hidden rounded-[calc(theme(borderRadius.lg)+1px)] max-lg:rounded-b-[calc(2rem+1px)] lg:rounded-r-[calc(2rem+1px)]">
                    <AQISensor value={aqi} liveData={liveData} />
                </div>
            </div>
        </div>

    )
}



export default BentoGrid