import ReactECharts from "echarts-for-react";
import _ from "lodash";
import { useWeatherData } from "../../queries/admin";
import { useEffect, useMemo, useState } from "react";
import AnalyticsSecondSection from "../new/AnalyticsSecondSection";
import { HStack, VStack } from "../../component/utils";
import ChartSelection from "../new/ChartSelection";
import { useValueStore } from "../../store/useValueState";
import { filterWeatherData } from "../../constants/TimeConstants";
import { IWeatherData } from "../../types/device";
import ParameterSelectorReport from "../new/ParameterSelectorReport";
import { motion } from "framer-motion";
import { ChartColumnStacked, FileSpreadsheet } from "lucide-react";

export const metricDataMapping: { [key: string]: keyof IWeatherData } = {
  Temperature: "temperature",
  Humidity: "humidity",
  Pressure: "pressure",
  "Carbon-Dioxide": "co2",
  VOCs: "vocs",
  Light: "light",
  Noise: "noise",
  PM1: "pm1",
  "PM2.5": "pm25",
  PM4: "pm4",
  PM10: "pm10",
  AIQ: "aiq",
  "Gas-1": "gas1",
  "Gas-2": "gas2",
  "Gas-3": "gas3",
  "Gas-4": "gas4",
  "Gas-5": "gas5",
  "Gas-6": "gas6",
};

function getMetrics(metric: string[]) {
  if (metric.length === 0) {
    return metricDataMapping["Temperature"];
  }
  if (typeof metric === "string") {
    return metricDataMapping[metric];
  }
  const metrics = metric.map((m) => metricDataMapping[m]).join(",");
  return metrics;
}

const AnalyticsPage = () => {
  const {
    metrics: metric = [],
    date,
    index,
    gap,
    deviceName,
    metricUnits: metricUnit = [],
    isRefresh,
    deviceId,
  } = useValueStore();

  const {
    data: _weatherData,
    refetch,
    isLoading,
  } = useWeatherData(
    deviceId ?? "",
    getMetrics(metric) || "Temperature",
    date ?? "1 Day"
  );
  const [weatherData, setWeatherData] = useState<IWeatherData[]>([]);
  useEffect(() => {
    if (_weatherData) {
      const weatherData = filterWeatherData(
        _weatherData ?? [],
        date ?? "1 Day",
        gap ?? "1 hour"
      );
      setWeatherData(weatherData || []);
    }
  }, [date, gap, index, metric, isRefresh, _weatherData]);

  useEffect(() => {
    refetch();
  }, [deviceId]);
  const series = useMemo(
    () =>
      metric.map((metric) => ({
        name: metric,
        type: "line",
        data: weatherData.map((weather) => weather[metricDataMapping[metric]]),
      })),
    [metric, weatherData]
  );
  const temperatureOptions = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
      },
      grid: {
        top: 40,
        bottom: 80,
        left: 80,
        right: 40,
      },
      xAxis: {
        type: "category",
        data: weatherData.map((d) => d.dateString),
      },
      legend: {
        data: metric,
        textStyle: {
          color: "#00A36C",
        },
      },
      series,
      visualMap: [
        {
          seriesIndex: 0,
          show: false,
          dimension: 1,
          inRange: {
            color: ["#00A36C", "#FFA500", "#FF4500"],
          },
          min: _.min(series.flatMap((s) => s.data)),
          max: _.max(series.flatMap((s) => s.data)),
        },
      ],
      yAxis: {
        type: "value",
        axisLabel: {
          formatter: `{value} ${
            metricUnit.length === 1 ? metricUnit[0] : "units"
          }`,
          fontSize: 14,
        },
        min: _.min(series.flatMap((s) => s.data)),
        max: _.max(series.flatMap((s) => s.data)),
      },
    }),
    [series]
  );

  return (
    <>
      <VStack className="gap-6">
        <AnalyticsSecondSection date={"today"} />

        <div className="bg-white rounded-xl border border-borderColor shadow-box p-6">
          {/* <div className="flex w-full justify-between bg-gray-50 border-b p-6 -m-6 ml-3 rounded-t-xl border-borderColor items-center mb-4"> */}
          <div className="bg-gray-50 flex justify-between border-b border-gray-200 px-8 pt-4 pb-4 mb-4 -m-6 rounded-t-xl">
            <HStack className="items-center gap-4">
              <motion.div
                animate={{
                  scale: [1, 1.05, 1],
                  rotateZ: [0, 0.5, -0.5, 0],
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
                className="w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-400 rounded-lg flex items-center justify-center shadow-lg"
              >
                <ChartColumnStacked size={24} className="text-white" />
              </motion.div>{" "}
              <VStack>
                <h2 className="text-xl font-bold">
                  {metric.length === 1 ? metric[0] : "Parameter"} Analysis
                </h2>
                <p className="text-xs font-medium text-gray-500 ">
                  showing the historic data{" "}
                  <span className="font-bold">
                    {metric.length === 1 ? metric[0] : "multiple"}
                  </span>{" "}
                  metrics of about <span className="font-bold">{date}</span> of{" "}
                  <span className="font-bold">{deviceName}</span>
                </p>
              </VStack>
            </HStack>
            <ChartSelection />
          </div>

          <div className="h-[500px] w-full">
            {isLoading ? (
              <>Loading</>
            ) : (
              <ReactECharts
                option={temperatureOptions}
                notMerge
                style={{ height: "100%", width: "100%" }}
              />
            )}
          </div>
        </div>
        <div className="bg-white rounded-xl border border-borderColor drop-shadow-sm p-6">
          <HStack className="items-center gap-4 bg-gray-50 border-b border-borderColor p-6 -m-6 rounded-t-xl">
            <motion.div
              animate={{
                scale: [1, 1.05, 1],
                rotateZ: [0, 0.5, -0.5, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse",
              }}
              className="w-12 h-12 bg-gradient-to-br from-green-500 to-green-400 rounded-lg flex items-center justify-center shadow-lg"
            >
              <FileSpreadsheet size={24} className="text-white" />
            </motion.div>{" "}
            <VStack>
              <h2 className="text-xl font-bold">Report Generation</h2>
              <p className="text-xs font-medium text-gray-500 ">
                fill out below information to generate a report
              </p>
            </VStack>
          </HStack>
          <div className="grid col-span-2 gap-x-4 gap-y-10">
            <ParameterSelectorReport />
          </div>
        </div>
      </VStack>
    </>
  );
};

export default AnalyticsPage;
