import ReactECharts from "echarts-for-react";
import { HStack, VStack } from "../../component/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Icon } from "@iconify/react";
import { CardModel } from "./GenerateDashboardData";
import { IWeatherData, IWeatherDataRange } from "../../types/device";

const ProductivityMeter = ({ value, liveData, deviceRange }: { value: CardModel, liveData?: IWeatherData, deviceRange?: IWeatherDataRange }) => {
  const renderBarChart = () => {
    const bars = 20; // Number of bars
    //value.value 
    const valueNumber = 10;
    const currentValue = Math.min(Math.max(Number(valueNumber), 0), 20); // Clamp value between 0-20

    return (
      <div className="w-full h-16 flex gap-1 items-end mt-4">
        {[...Array(bars)].map((_, index) => (
          <div
            key={index}
            className={`flex-1 rounded-sm ${
              index < currentValue ? 'bg-blue-600' : 'bg-blue-100'
            }`}
            style={{
              height: '100%',
              animation: `fillBar 2s ease-in-out forwards`,
              animationDelay: `${index * 50}ms`,
              transform: 'scaleY(0)',
              transformOrigin: 'bottom'
            }}
          />
        ))}
        <style>
          {`
            @keyframes fillBar {
              0% { transform: scaleY(0); }
              50% { transform: scaleY(1); background-color: rgb(99 102 241); }
              100% { transform: scaleY(1); }
            }
          `}
        </style>
      </div>
    );
  };

  return (
    <>
      <div id={value.key} className="relative flex-1 bg-white border border-borderColor  drop-shadow-box  p-4 py-6 rounded-2xl">
        <VStack className="gap-4 justify-between h-full">
          <VStack>


            <HStack className="w-full whitespace-nowrap items-center justify-between">
              <HStack className="items-center ">
                <p className="text-sm text-secondary font-medium">
                  {value.name}
                </p>
                <InformationCircleIcon className="w-4 h- ml-2 text-gray-400" />
              </HStack>
              <button
                type="button"
                className="absolute right-4 top-4 rounded-xl bg-white px-3 py-2.5 text-xs1 font-semibold text-gray-900 shadow-box  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                See Details
              </button>
            </HStack>
            <HStack className="gap-2 mt-6 mb-2">
              <div className="p-2 shadow-box bg-gray-50 border-gray-200 border rounded-lg">
                <Icon
                  className="w-5 h-5 text-primary font-bold"
                  icon={"line-md:speedometer-loop"}
                />
              </div>
              <p className="text-secondary text-3xl font-semibold">
                {value.value}
              </p>
              <div className="p-1 shadow-box h-6 text-sm bg-green-50 text-green-500 font-semibold rounded-lg">
                {value.change}
              </div>
            </HStack>
          </VStack>
          {renderBarChart()}
          <HStack className="gap-2 mt-2">
            <p className="text-gray-500 text-xs1">{value.content}</p>
          </HStack>
        </VStack>
      </div>
    </>
  );
};

export default ProductivityMeter;
