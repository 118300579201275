import React from "react";

const StatsDisplay = ({ listData }: { listData: any[] }) => {
  const gridCols: any = {
    2: "grid-cols-2",
    3: "grid-cols-3", 
    4: "grid-cols-2 sm:grid-cols-4",
    5: "grid-cols-2 sm:grid-cols-3 lg:grid-cols-5",
    6: "grid-cols-2 sm:grid-cols-3 lg:grid-cols-6",
  };
  return (
    <div className="relative drop-shadow-box">
      <div className="flex justify-start">
        <div className="px-3 py-1 bg-white text-sm font-medium text-gray-600 rounded-t-lg border-t border-l border-r border-b-0 border-borderColor">
          QuickView
        </div>
      </div>
      <dl
        className={`w-full mx-auto grid ${
          gridCols[listData.length]
        } gap-px bg-borderColor border border-borderColor shadow-sm rounded-b-xl rounded-e-xl whitespace-nowrap`}
      >
        {listData.map((stat, index) => (
          <div
            key={stat.name}
            className={`flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-8 sm:px-6 xl:px-8
           ${
             index === 0
               ? "rounded-bl-xl"
               : index === listData.length - 1
               ? "rounded-r-xl"
               : ""
           }
            `}
          >
            <dt className="text-sm font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>

            <dd className="w-full flex gap-2 flex-row text-3xl font-medium leading-10 tracking-tight text-gray-900">
              <p className="text-secondary text-3xl font-semibold">
                {stat.value}
              </p>
              <p className="text-gray-400 text-lg pt-2 font-medium">
                {stat.unit}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default StatsDisplay;
