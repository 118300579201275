import { IWeatherData, IWeatherDataRange } from "../../types/device";
import { IGasMapping } from "../user/CompanyPage";

export interface CardModel {
  key: string;
  name: string;
  value: string;
  change?: string;
  content: string;
  info: string;
  graph: string;
  unit: string;
  min?: number;
  max?: number;
  iconName: string;
  seeDetailKey?: string;
}

export const extractDashboardCardValues = (
  liveData?: IWeatherData,
  gasMapping?: IGasMapping,
  deviceRange?: IWeatherDataRange
) => {
  const pmAvg =
    ((liveData?.pm1 ?? 0) +
      (liveData?.pm10 ?? 0) +
      (liveData?.pm25 ?? 0) +
      (liveData?.pm4 ?? 0)) /
    4;
  const temp_Humid_Pres_Co2: CardModel[] = [
    {
      name: "Temperature",
      value: `${liveData?.temperature.toFixed(2) || "-"}`,
      unit: "°C",
      key: "Temperature",
      seeDetailKey: "Temperature",
      min: deviceRange?.temperatureMin ?? 20,
      max: deviceRange?.temperatureMax ?? 24,
      // change: "83.2%",
      iconName: "solar:temperature-linear",
      info: "Temperature",
      content: `Between ${deviceRange?.temperatureMin ?? 20}-${
        deviceRange?.temperatureMax ?? 24
      }°C for optimal comfort`,
      graph: "Line",
    },
    {
      name: "Humidity",
      value: `${liveData?.humidity.toFixed(0) || "-"}`,
      unit: "%",
      seeDetailKey: "Humidity",
      min: deviceRange?.humidityMin ?? 40,
      max: deviceRange?.humidityMax ?? 60,
      key: "Humidity",
      iconName: "lets-icons:humidity-light",
      info: "Humidity",
      // change: "83.2%",
      content: `Between ${deviceRange?.humidityMin ?? 40}-${
        deviceRange?.humidityMax ?? 60
      }% RH for optimal comfort`,
      graph: "Bar",
    },
    {
      name: "Pressure",
      unit: "hPa",
      key: "Pressure",
      seeDetailKey: "Pressure",
      min: deviceRange?.pressureMin ?? 1000,
      max: deviceRange?.pressureMax ?? 1020,
      value: `${liveData?.pressure.toFixed(0) || "-"}`,
      // change: "83.2%",
      iconName: "mdi:barometer",
      info: "Pressure",
      content: `Between ${deviceRange?.pressureMin ?? 1000}-${
        deviceRange?.pressureMax ?? 1020
      } hPa for optimal comfort`,
      graph: "",
    },
    {
      name: "Carbon Dioxide (CO2)",
      value: `${liveData?.co2.toFixed(0) || "-"}`,
      unit: "ppm",
      seeDetailKey: "Carbon-Dioxide",
      key: "Carbon Dioxide",
      // change: "83.2%",
      min: deviceRange?.co2Min ?? 0,
      max: deviceRange?.co2Max ?? 1000,
      iconName: "iwwa:co2",
      info: "Carbon Dioxide(CO2)",
      content: `Between ${deviceRange?.co2Min ?? 1000}-${
        deviceRange?.co2Max ?? 1020
      } ppm for optimal comfort`,
      graph: "Line",
    },
  ];

  const pm_voc_light_noise: CardModel[] = [
    {
      name: "PM Index",
      value: `${pmAvg.toFixed(0) || "-"}`,
      unit: "%",
      key: "pm",
      min: 0,
      max: 100,
      iconName: "fluent:mold-24-regular",
      info: "pm",
      content: `Between ${deviceRange?.pm1Min ?? 0}-${
        deviceRange?.pm1Max ?? 100
      } for optimal comfort`,
      graph: "Line",
    },
    {
      name: "VOCs",
      key: "VOCs",
      seeDetailKey: "VOCs",
      value: `${liveData?.vocs.toFixed(0) || "-"}`,
      min: deviceRange?.vocsMin ?? 0,
      max: deviceRange?.vocsMax ?? 0,
      unit: "µg/m³",
      iconName: "material-symbols:water-voc-outline-rounded",
      info: "Volatile Organic Compounds (VOCs)",
      // change: "83.2%",
      content: `Between ${deviceRange?.vocsMin ?? 0}-${
        deviceRange?.vocsMax ?? 300
      } µg/m³ for optimal comfort`,
      graph: "Line",
    },
    {
      name: "Light",
      key: "Light",
      seeDetailKey: "Light",
      value: `${liveData?.light.toFixed(0) || "-"}`,
      unit: "lux",
      min: deviceRange?.lightMin ?? 300,
      max: deviceRange?.lightMax ?? 500,
      // change: "83.2%",
      iconName: "ph:sun-light",
      info: "Pressure",
      content: `Between ${deviceRange?.lightMin ?? 300}-${
        deviceRange?.lightMax ?? 500
      } lux for optimal comfort`,
      graph: "",
    },
    {
      name: "Noise",
      key: "Noise",
      seeDetailKey: "Noise",
      value: `${liveData?.noise.toFixed(0) || "-"}`,
      unit: "dB",
      // change: "83.2%",
      min: deviceRange?.noiseMin ?? 0,
      max: deviceRange?.noiseMax ?? 55,
      info: "Differential Pressure",
      iconName: "material-symbols:noise-aware",
      content: `Between ${deviceRange?.noiseMin ?? 0}-${
        deviceRange?.noiseMax
      } dB for optimal comfort`,
      graph: "",
    },
  ];

  const gas1: CardModel[] = [
    {
      name: gasMapping?.gas1 ?? "Gas 1",
      key: "Gas 1",
      value: `${liveData?.gas1.toFixed(0) || "-"}`,
      unit: "ppm",
      // change: "83.2%",
      seeDetailKey: "Gas-1",
      min: deviceRange?.gas1Min ?? 0,
      max: deviceRange?.gas1Max ?? 1000,
      iconName: "akar-icons:air",
      info: "Temperature",
      content: `Between ${deviceRange?.gas1Min ?? 0}-${
        deviceRange?.gas1Max ?? 1000
      } ppm for optimal comfort`,
      graph: "Line",
    },
  ];

  const gas2: CardModel[] = [
    {
      name: gasMapping?.gas2 ?? "Gas 2",
      key: "Gas 2",
      seeDetailKey: "Gas-2",
      value: `${liveData?.gas2.toFixed(0) || "-"}`,
      unit: "ppm",
      min: deviceRange?.gas2Min ?? 0,
      max: deviceRange?.gas2Max ?? 1000,
      iconName: "akar-icons:air",
      info: "Volatile Organic Compounds (VOCs)",
      content: `Between ${deviceRange?.gas2Min ?? 0}-${
        deviceRange?.gas2Max ?? 1000
      } ppm for optimal comfort`,
      graph: "Line",
    },
  ];

  const gas3_gas4_gas5_gas6: CardModel[] = [
    {
      name: gasMapping?.gas3 ?? "Gas 3",
      key: "Gas 3",
      seeDetailKey: "Gas-3",
      value: `${liveData?.gas3.toFixed(0) || "-"}`,
      unit: "ppm",
      min: deviceRange?.gas3Min ?? 0,
      max: deviceRange?.gas3Max ?? 1000,
      iconName: "akar-icons:air",
      info: "Pressure",
      content: `Between ${deviceRange?.gas3Min ?? 0}-${
        deviceRange?.gas3Max ?? 1000
      } ppm for optimal comfort`,
      graph: "",
    },
    {
      name: gasMapping?.gas4 ?? "Gas 4",
      key: "Gas 4",
      seeDetailKey: "Gas-4",
      min: deviceRange?.gas4Min ?? 0,
      max: deviceRange?.gas4Max ?? 1000,
      value: `${liveData?.gas4.toFixed(0) || "-"}`,
      unit: "ppm",
      // change: "83.2%",
      iconName: "akar-icons:air",
      info: "Temperature",
      content: `Between ${deviceRange?.gas4Min ?? 0}-${
        deviceRange?.gas4Max ?? 1000
      } ppm for optimal comfort`,
      graph: "Line",
    },
    {
      name: gasMapping?.gas5 ?? "Gas 5",
      key: "Gas 5",
      seeDetailKey: "Gas-5",
      min: deviceRange?.gas5Min ?? 0,
      max: deviceRange?.gas5Max ?? 1000,
      value: `${liveData?.gas5.toFixed(0) || "-"}`,
      unit: "ppm",
      iconName: "akar-icons:air",
      info: "Volatile Organic Compounds (VOCs)",
      content: `Between ${deviceRange?.gas5Min ?? 0}-${
        deviceRange?.gas5Max ?? 1000
      } ppm for optimal comfort`,

      graph: "Line",
    },
    {
      name: gasMapping?.gas6 ?? "Gas 6",
      key: "Gas 6",
      seeDetailKey: "Gas-6",
      min: deviceRange?.gas6Min ?? 0,
      max: deviceRange?.gas6Max ?? 1000,
      value: `${liveData?.gas6.toFixed(0) || "-"}`,
      unit: "ppm",
      // change: "83.2%",
      iconName: "akar-icons:air",
      info: "Pressure",

      content: `Between ${deviceRange?.gas6Min ?? 0}-${
        deviceRange?.gas6Max ?? 1000
      } ppm for optimal comfort`,

      graph: "",
    },
  ];
  // const data3: CardModel[] = [
  //   {
  //     name: gasMapping?.gas1 ?? "Gas 1",
  //     key: "Gas 1",
  //     value: `${liveData?.gas1.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     seeDetailKey: "Gas-1",
  //     // change: "83.2%",
  //     min: deviceRange?.gas1Min ?? 0,
  //     max: deviceRange?.gas1Max ?? 1000,
  //     iconName: "akar-icons:air",
  //     info: "Temperature",
  //     content: `Between ${deviceRange?.gas1Min ?? 0}-${deviceRange?.gas1Max ?? 1000
  //       } ppm for optimal comfort`,
  //     graph: "Line",
  //   },
  //   {
  //     name: gasMapping?.gas2 ?? "Gas 2",
  //     key: "Gas 2",

  //     value: `${liveData?.gas2.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     min: deviceRange?.gas2Min ?? 0,
  //     max: deviceRange?.gas2Max ?? 1000,
  //     iconName: "akar-icons:air",
  //     info: "Volatile Organic Compounds (VOCs)",
  //     // change: "83.2%",
  //     content: `Between ${deviceRange?.gas2Min ?? 0}-${deviceRange?.gas2Max ?? 1000
  //       } ppm for optimal comfort`,
  //     graph: "Line",
  //   },
  //   {
  //     name: gasMapping?.gas3 ?? "Gas 3",
  //     key: "Gas 3",
  //     min: deviceRange?.gas3Min ?? 0,
  //     max: deviceRange?.gas3Max ?? 1000,
  //     value: `${liveData?.gas3.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     // change: "83.2%",
  //     iconName: "akar-icons:air",
  //     info: "Pressure",
  //     content: `Between ${deviceRange?.gas3Min ?? 0}-${deviceRange?.gas3Max ?? 1000
  //       } ppm for optimal comfort`,
  //     graph: "",
  //   },
  // ];
  // const data4: CardModel[] = [
  //   {
  //     name: gasMapping?.gas4 ?? "Gas 4",
  //     key: "Gas 4",
  //     min: deviceRange?.gas4Min ?? 0,
  //     max: deviceRange?.gas4Max ?? 1000,
  //     value: `${liveData?.gas4.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     // change: "83.2%",
  //     iconName: "akar-icons:air",
  //     info: "Temperature",
  //     content: `Between ${deviceRange?.gas4Min ?? 0}-${deviceRange?.gas4Max ?? 1000
  //       } ppm for optimal comfort`,
  //     graph: "Line",
  //   },
  //   {
  //     name: gasMapping?.gas5 ?? "Gas 5",
  //     key: "Gas 5",
  //     min: deviceRange?.gas5Min ?? 0,
  //     max: deviceRange?.gas5Max ?? 1000,
  //     value: `${liveData?.gas5.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     iconName: "akar-icons:air",
  //     info: "Volatile Organic Compounds (VOCs)",
  //     content: `Between ${deviceRange?.gas5Min ?? 0}-${deviceRange?.gas5Max ?? 1000
  //       } ppm for optimal comfort`,

  //     graph: "Line",
  //   },
  //   {
  //     name: gasMapping?.gas6 ?? "Gas 6",
  //     key: "Gas 6",
  //     min: deviceRange?.gas6Min ?? 0,
  //     max: deviceRange?.gas6Max ?? 1000,
  //     value: `${liveData?.gas6.toFixed(0) || "-"}`,
  //     unit: "ppm",
  //     // change: "83.2%",
  //     iconName: "akar-icons:air",
  //     info: "Pressure",

  //     content: `Between ${deviceRange?.gas6Min ?? 0}-${deviceRange?.gas6Max ?? 1000
  //       } ppm for optimal comfort`,

  //     graph: "",
  //   },
  // ];
  const productivityMeter = {
    name: "Productivity Meter",
    value: `${liveData?.productivityMeter?.toFixed(0) || "-"}`,
    key: "Productivity Meter",
    iconName: "carbon:pressure",
    // change: "83.2%",
    info: "Mold Growth",
    content: "",
    graph: "Line",
    unit: "",
  };
  const Odor_Mold_Occup_Vent: CardModel[] = [
    {
      name: "Odor",
      value: `${(liveData?.odor ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "Odor",
      // seeDetailKey: "Odor",
      min: deviceRange?.odorMin ?? 0,
      max: deviceRange?.odorMax ?? 0,
      iconName: "fluent:mold-24-regular",
      info: "Odor",
      content: `Between ${deviceRange?.odorMin ?? 0}-${
        deviceRange?.odorMax ?? 30
      } ppm for optimal comfort`,
      graph: "Line",
    },
    {
      name: "Mold Growth",
      value: `${(liveData?.moldGrowth ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      // seeDetailKey: "MoldGrowth",
      min: deviceRange?.moldGrowthMin ?? 0,
      max: deviceRange?.moldGrowthMax ?? 30,
      key: "MoldGrowth",
      iconName: "fluent:mold-24-regular",
      info: "Mold Growth",
      graph: "Bar",
      content: `Between ${deviceRange?.moldGrowthMin ?? 0}-${
        deviceRange?.moldGrowthMax ?? 30
      } % for optimal comfort`,
    },
    {
      name: "Occupancy",
      value: `${(liveData?.occupancy ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "Occupancy",
      // seeDetailKey: "Occupancy",
      iconName: "fluent:mold-24-regular",
      info: "Occupancy",
      graph: "Bar",
      content: `Between ${deviceRange?.occupancyMin ?? 0}-${
        deviceRange?.occupancyMax ?? 100
      } % for optimal comfort`,
    },
    {
      name: "Ventilation",
      value: `${(liveData?.ventilation ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      // seeDetailKey: "Ventilation",
      key: "Ventilation",
      iconName: "fluent:mold-24-regular",
      info: "Ventilation",
      graph: "Bar",
      content: `Between ${deviceRange?.ventilationMin ?? 0}-${
        deviceRange?.ventilationMax ?? 100
      } % for optimal comfort`,
    },
  ];

  const pm1_10_25_4: CardModel[] = [
    {
      name: "PM1",
      value: `${(liveData?.pm1 ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "pm1",
      min: deviceRange?.pm1Min ?? 0,
      max: deviceRange?.pm1Max ?? 0,
      // change: "83.2%",
      iconName: "fluent:mold-24-regular",
      info: "PM1",
      content: `Between ${deviceRange?.pm1Min ?? 0}-${
        deviceRange?.pm1Max ?? 0
      } for optimal comfort`,
      graph: "Line",
    },
    {
      name: "PM10",
      value: `${(liveData?.pm10 ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "pm10",
      min: deviceRange?.pm10Min ?? 0,
      max: deviceRange?.pm10Max ?? 0,
      // change: "83.2%",
      iconName: "fluent:mold-24-regular",
      info: "PM10",
      content: `Between ${deviceRange?.pm10Min ?? 0}-${
        deviceRange?.pm10Max ?? 0
      } for optimal comfort`,

      graph: "Line",
    },
    {
      name: "PM25",
      value: `${(liveData?.pm25 ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "pm25",
      min: deviceRange?.pm25Min ?? 0,
      max: deviceRange?.pm25Max ?? 0,
      // change: "83.2%",
      iconName: "fluent:mold-24-regular",
      info: "PM25",
      content: `Between ${deviceRange?.pm25Min ?? 0}-${
        deviceRange?.pm25Max ?? 0
      } for optimal comfort`,
      graph: "Line",
    },
    {
      name: "PM4",
      value: `${(liveData?.pm4 ?? 0).toFixed(0) || "-"}`,
      unit: "%",
      key: "pm4",
      min: deviceRange?.pm4Min ?? 0,
      max: deviceRange?.pm4Max ?? 0,
      iconName: "fluent:mold-24-regular",
      info: "PM4",
      content: `Between ${deviceRange?.pm4Min ?? 0}-${
        deviceRange?.pm4Max ?? 0
      } for optimal comfort`,
      graph: "Line",
    },
  ];

  return {
    temp_Humid_Pres_Co2,
    pm1_10_25_4,
    Odor_Mold_Occup_Vent,
    pm_voc_light_noise,
    productivityMeter,
    gas1,
    gas2,
    gas3_gas4_gas5_gas6,
  };
};
