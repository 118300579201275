import React from 'react';
 // Assuming this is the path to our generic component
import { useValueStore } from "../../store/useValueState";
import { CheckIcon } from 'lucide-react';
import GenericDropdown from './GenericSelection';
import { calculateTimeGaps, TimeModel, timePeriods } from '../../constants/TimeConstants';

const DateSelector: React.FC = () => {
  const { date, setValue } = useValueStore();

  const handleSelect = (selectedPeriod: TimeModel) => {
    setValue({ date: selectedPeriod.name });
    const newTimeGaps = calculateTimeGaps(selectedPeriod.name);
    setValue({gap: newTimeGaps[0].name});
  };
  console.log("rordate", date);

  return (
    <div className="flex space-x-1 rounded-lg bg-gray-100 p-1">
      {timePeriods.map((period) => (
        <button
          key={period.id}
          onClick={() => handleSelect(period)}
          className={`
            px-3 py-1.5 text-sm font-medium rounded-md transition-colors
            ${period.name === date 
              ? 'bg-white text-gray-900 shadow-sm' 
              : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
            }
          `}
        >
          {period.name}
        </button>
      ))}
    </div>
  );
};

export default DateSelector;