import React from 'react';
import { Wifi, Signal, Battery, Thermometer, Server, Hash, MapPin, Square, Cpu, Activity } from 'lucide-react';
import { motion } from 'framer-motion';
import { EClusterType, IDeviceModel } from '../user/CompanyPage';

const DeviceDetails = ({deviceInfo}: {deviceInfo?:IDeviceModel}) => {
  const statusItems = [
    { label: 'DH', icon: <Thermometer size={18} />, status: 'active' },
    { label: 'WIFI', icon: <Wifi size={18} />, status: 'active' },
    { label: 'SIM', icon: <Signal size={18} />, status: 'inactive' },
    { label: 'Battery', icon: <Battery size={18} />, status: 'active' }
  ];

  const detailsItems = [
    { icon: <Server size={18} />, label: 'Device Type', value:  EClusterType.CLUSTERED === deviceInfo?.clusterType  ? 'Clustered' + (deviceInfo.numberOfClusteredDevice) : 'Individual' },
    { icon: <Hash size={18} />, label: 'Device ID', value: deviceInfo?.id },
    { icon: <MapPin size={18} />, label: 'Location', value: deviceInfo?.location },
    { icon: <Square size={18} />, label: 'Area', value: deviceInfo?.area },
    { icon: <Cpu size={18} />, label: 'Firmware', value: deviceInfo?.firmwareVersion ? `Firmware Version V${deviceInfo?.firmwareVersion}`: '' }
  ].filter(item => item.value);

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="w-full mx-auto mb-8 bg-white rounded-lg border border-borderColor shadow-sm overflow-hidden"
    >
      {/* Header Section */}
      <div className="p-6 border-b border-gray-200 bg-gradient-to-r from-gray-50 to-white">
        <div className="flex justify-between items-center">
          <motion.div 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex items-center gap-4"
          >
            <div className="relative">
              <motion.div
                animate={{ 
                  scale: [1, 1.1, 1],
                  rotateZ: [0, 5, -5, 0]
                }}
                transition={{ 
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="w-12 h-12 bg-gradient-to-br from-blue-500 to-cyan-400 rounded-lg flex items-center justify-center shadow-lg"
              >
                <Activity size={24} className="text-white" />
              </motion.div>
              <motion.div 
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                className="absolute -top-1 -right-1 w-3 h-3 bg-green-500 rounded-full border-2 border-white"
              />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-800">{deviceInfo?.name}</h2>
              <div className="flex items-center gap-2 mt-1">
                <div className="w-2 h-2 bg-green-500 rounded-full" />
                <span className="text-sm text-gray-600">{deviceInfo?.status}</span>
              </div>
            </div>
          </motion.div>
          
          {/* Status Indicators */}
          <div className="flex gap-6">
            {statusItems.map((item, index) => (
              <motion.div 
                key={item.label}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="flex flex-col items-center"
              >
                <div className={`
                  bg-gray-50 px-4 py-2 rounded-lg shadow-sm hover:shadow-md transition-all duration-200
                  ${item.status === 'active' ? 'border-l-4 border-green-500' : 'border-l-4 border-gray-300'}
                `}>
                  <div className="flex items-center gap-2">
                    <div className={`${item.status === 'active' ? 'text-green-500' : 'text-gray-400'}`}>
                      {item.icon}
                    </div>
                    <span className="text-sm font-medium text-gray-600">{item.label}</span>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Main divider */}
      {/* <div className="h-px bg-gray-200 w-full my-4" /> */}

      {/* Details Grid */}
      <div className="p-6 bg-white">
        <h3 className="text-lg font-medium text-gray-800 mb-4">Device Information</h3>
        <div className="flex flex-wrap gap-4">
          {detailsItems.map((item, index) => (
            <React.Fragment key={index}>
              <motion.div 
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
                className="bg-gray-50 p-4 flex-1 rounded-lg shadow-sm hover:shadow-md transition-all duration-200 hover:bg-gray-100"
              >
                <div className="flex items-center gap-3">
                  <div className="p-2 bg-white rounded-md shadow-sm">
                    <div className="text-blue-500">
                      {item.icon}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-sm text-gray-500">{item.label}</span>
                    <span className="text-sm font-medium text-gray-700">
                      {item.value}
                    </span>
                  </div>
                </div>
              </motion.div>
              {/* Responsive divider that only shows on mobile */}
              {index < detailsItems.length - 1 && (
                <div className="h-px bg-gray-200 w-full my-2 block lg:hidden col-span-full" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default DeviceDetails;