import React from 'react';
import { motion } from 'framer-motion';
import { Activity } from 'lucide-react';

interface DeviceStatusIconProps {
  isOnline: boolean;
  size?: 'sm' | 'md' | 'lg';
  showLabel?: boolean;
  className?: string;
  lastUpdated?: Date;
}

const DeviceStatusIcon: React.FC<DeviceStatusIconProps> = ({ 
  isOnline, 
  size = 'md',
  showLabel = true,
  className = '',
  lastUpdated = new Date()
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  // Size mappings
  const sizeClasses = {
    sm: {
      container: 'w-6 h-6',
      icon: 16,
      dot: 'w-2 h-2',
      text: 'text-xs',
      tooltip: 'text-xs'
    },
    md: {
      container: 'w-10 h-10',
      icon: 24,
      dot: 'w-3 h-3',
      text: 'text-sm',
      tooltip: 'text-sm'
    },
    lg: {
      container: 'w-14 h-14',
      icon: 32,
      dot: 'w-4 h-4',
      text: 'text-base',
      tooltip: 'text-base'
    }
  };

  const formatLastUpdated = (date: Date) => {
    return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
      -Math.round((new Date().getTime() - date.getTime()) / 60000),
      'minutes'
    );
  };

  return (
    <div className={`flex flex-col items-center gap-2 ${className}`}>
      <div 
        className="relative group"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <motion.div
          animate={{ 
            scale: isOnline ? [1, 1.05, 1] : 1,
            rotate: isOnline ? [0, 5, -5, 0] : 0
          }}
          transition={{ 
            duration: isOnline ? 4 : 0,
            repeat: Infinity,
            repeatType: "reverse"
          }}
          className={`
            ${sizeClasses[size].container}
            rounded-xl 
            flex 
            items-center 
            border border-borderColor
            justify-center 
            shadow-md
            transition-colors
            duration-500
            relative
            ${isOnline 
              ? 'bg-gradient-to-br from-green-500 to-green-400' 
              : 'bg-white'
            }
          `}
        >
          <motion.div
            animate={
              !isOnline 
                ? { 
                    // rotate: [0, 360],
                    scale: [1, 1, 1]
                  }
                : { 
                    // rotate: 0,
                    scale: 0.9
                  }
            }
            transition={{
              rotate: {
                duration: 8,
                repeat: Infinity,
                ease: "linear"
              },
              scale: {
                duration: 2,
                repeat: Infinity,
                repeatType: "reverse"
              }
            }}
          >
            <img src="/SenseNode_Icon.ico" alt="activity" className="w-7 h-7" />
            {/* <Activity 
              size={sizeClasses[size].icon} 
              className={`text-white transform p-1 transition-all duration-300 ${!isOnline && 'opacity-50'}`}
            /> */}
          </motion.div>
        </motion.div>
        
        {/* Status indicator dot */}
        <motion.div
          animate={{
            scale: [1, 1.2, 1],
            opacity: isOnline ? [1, 0.7, 1] : 0.5
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          className={`
            absolute 
            -top-1 
            -right-1 
            ${sizeClasses[size].dot}
            rounded-full 
            border-2 
            border-white
            ${!isOnline ? 'bg-green-500' : 'bg-gray-400'}
            transition-colors
            duration-500
          `}
        />

        {/* Hover tooltip */}
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ 
            opacity: isHovered ? 1 : 0,
            y: isHovered ? 0 : 10
          }}
          transition={{
            duration: 0.2
          }}
          className={`
            absolute 
            left-1/2 
            -translate-x-1/2
            -bottom-12
            whitespace-nowrap
            bg-gray-500 
            text-white 
            px-3 
            py-1.5 
            rounded-xl
            ${sizeClasses[size].tooltip}
          `}
        >
          Last updated: {formatLastUpdated(lastUpdated)}
        </motion.div>
      </div>
      
      {showLabel && (
        <div className="flex items-center gap-1.5">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ 
              scale: 1,
              backgroundColor: isOnline ? '#22c55e' : '#9ca3af'
            }}
            transition={{ 
              type: "spring", 
              stiffness: 500, 
              damping: 30,
              backgroundColor: {
                duration: 0.5
              }
            }}
            className={`
              ${sizeClasses[size].dot}
              rounded-full
              transition-colors
              duration-500
            `}
          />
          <span className={`
            font-medium
            ${sizeClasses[size].text}
            transition-colors
            duration-500
            ${isOnline ? 'text-green-700' : 'text-gray-600'}
          `}>
            {isOnline ? 'Online' : 'Offline'}
          </span>
        </div>
      )}
    </div>
  );
};

export default DeviceStatusIcon;